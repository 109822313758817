<template>
	<BaseHeader>
		<button class="button is-light" v-if="isLoggedIn" @click="signout">
			<span class="icon">
				<icon icon="sign-out-alt" />
			</span>
			<span>Signout</span>
		</button>
	</BaseHeader>
	<router-view></router-view>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue"
export default {
	components: {
		BaseHeader
	},
	data() {
		return {
			isLoggedIn: true
		}
	},
	methods: {
		signout() {
			this.isLoggedIn = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
